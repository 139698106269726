// Import our CSS
import "@/scss/swiper.scss";

import Swiper from "swiper";
import { Navigation, Pagination, Autoplay, Thumbs } from "swiper/modules";

window.Swiper = Swiper;

let sliders = document.querySelectorAll(".swiper-slider");
sliders.forEach((slider) => {
    let settings = {};
    let modules = { modules: [] };

    settings["modules"] = [];
    if (slider.dataset.modulenav) {
        modules["modules"].push(Navigation);
    }

    if (slider.dataset.modulepagination) {
        modules["modules"].push(Pagination);
    }

    if (slider.dataset.moduleautoplay) {
        modules["modules"].push(Autoplay);
    }

    if (slider.dataset.settings) {
        settings = JSON.parse(slider.dataset.settings);
    }

    settings = merge(modules, settings);
    let swiper = new Swiper(slider, settings);
});

function merge(modules, settings) {
    for (var key in settings) {
        if (settings.hasOwnProperty(key)) modules[key] = settings[key];
    }
    return modules;
}

/*let thumbSliderproduct = document.querySelector(".thumbsliderproduct");
if (thumbSliderproduct) {
    var thumbslider = new Swiper(".thumbsliderproduct", {
        spaceBetween: 10,
        slidesPerView: 14,
        freeMode: true,
        watchSlidesProgress: true,
        modules: [Navigation, Pagination, Autoplay, Thumbs],
    });

    var productslider = new Swiper(".productslider", {
        loop: true,
        spaceBetween: 32,
        slidesPerView: 1.5,
        autoHeight: true,
        direction: "vertical",
        // navigation: {
        //     nextEl: ".swiper-button-next-product",
        //     prevEl: ".swiper-button-prev-product",
        // },
        thumbs: {
            swiper: thumbSliderproduct,
        },
        modules: [Navigation, Pagination, Autoplay, Thumbs],
    });
}*/

let herothumbslider = document.querySelector(".herothumbslider");
if (herothumbslider) {
    var thumbslider = new Swiper(".herothumbslider", {
        spaceBetween: 10,
        loop: true,
        slidesPerView: 1.5,
        watchSlidesProgress: true,
        progressBar: true,
        autoplay: {
            delay: 8000,
        },
        breakpoints: {
            540: {
                slidesPerView: 2.5,
                spaceBetween: 10,
            },
            640: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 4,
            },
        },
        modules: [Navigation, Pagination, Autoplay, Thumbs],
    });

    var heroslider = new Swiper(".heroslider", {
        loop: true,
        spaceBetween: 0,
        slidesPerView: 1,
        autoHeight: true,
        autoplay: {
            delay: 8000,
        },
        // navigation: {
        //     nextEl: ".swiper-button-next-product",
        //     prevEl: ".swiper-button-prev-product",
        // },
        on: {
            autoplayTimeLeft(s, time, progress) {
                const currentIndex =
                    s.realIndex !== undefined ? s.realIndex : s.activeIndex;
                const currentSlide = thumbslider.slides[currentIndex];
                const progressBar = currentSlide.querySelector(".progressBar");
                if (progressBar) {
                    const widthPercentage = parseFloat(progress) * 100;
                    progressBar.style.width = widthPercentage + "%";
                }
            },
        },
        thumbs: {
            swiper: herothumbslider,
        },
        modules: [Navigation, Pagination, Autoplay, Thumbs],
    });
}
